import React from 'react'
import { PageProps } from 'gatsby'
import { siteMetadata } from '../../gatsby-config'

import Layout from '../components/Layout'

import PageSection from '../components/PageSection'
import SectionHeaderRow from '../components/SectionHeaderRow'
import Meta from '../components/Meta'

const AGB: React.FC<PageProps> = ({ location }: PageProps) => {
  return (
    <Layout location={location}>
      <Meta site={siteMetadata} title="AGB" />
      <PageSection>
        <SectionHeaderRow headline="AGB" />
        <div className="row">
          <div className="col-12">
            <p>Allgemeine Gesch&auml;ftsbedingungen</p>
            <p>Geltungsbereich</p>
            <p>
              Diese Gesch&auml;ftsbedingungen gelten f&uuml;r alle K&auml;ufe
              bei www.veronikabogusch.com, die von Privatkunden get&auml;tigt
              werden.{' '}
            </p>
            <p>
              Privatkunden in diesem Sinne sind Personen mit Wohnsitz und
              Lieferadresse in der Bundesrepublik Deutschland, soweit die von
              ihnen bestellten Waren weder ihrer gewerblichen noch ihrer
              selbstst&auml;ndigen beruflichen T&auml;tigkeit zugerechnet werden
              k&ouml;nnen.{' '}
            </p>
            <p>Vertragsabschluss </p>
            <p>
              Die Pr&auml;sentation unserer Waren und der Einr&auml;umung der
              M&ouml;glichkeit zur Bestellung stellt unsererseits ein konkretes
              Angebot zum Abschluss eines Kaufvertrages dar.{' '}
            </p>
            <p>
              Durch Ihre Bestellung nehmen Sie das Angebot an und der
              Kaufvertrag ist zustande gekommen.
            </p>
            <p>
              Hier&uuml;ber erhalten Sie eine Bestellbest&auml;tigung per E-Mail
              an die von Ihnen angegebene E-Mail-Adresse.{' '}
            </p>
            <p>Preise und Versandkosten</p>
            <p>
              Die ausgezeichneten Preise sind Endpreise inkl. Umsatzsteuer. Es
              gilt der Betrag, der jeweils zum Zeitpunkt der verbindlichen
              Bestellung ausgewiesen ist. Hinzu kommen Versandkosten, die von
              der Versandart und der Gr&ouml;&szlig;e und dem Gewicht der von
              Ihnen bestellten Ware(n) abh&auml;ngig sind. &Uuml;ber die
              Einzelheiten k&ouml;nnen Sie sich unter Domain Versand und
              R&uuml;ckversand&nbsp;informieren. Die regelm&auml;&szlig;igen
              Kosten der R&uuml;cksendung, die im Falle einer R&uuml;ckgabe der
              Ware durch Sie in Aus&uuml;bung Ihres Widerrufsrechts Domain
              Widerrufsbelehrung&nbsp;entstehen, tragen wir. Bei Aus&uuml;bung
              Ihres Widerrufsrechts erstatten wir Ihnen auch die Versandkosten
              zur&uuml;ck.
            </p>
            <p>Zahlung</p>
            <p>Die Bezahlung erfolgt bei Lieferung mittels </p>
            <p>- Amazon Pay </p>
            <p>- Kreditkarte </p>
            <p>- PayPal </p>
            <p>- Klarna </p>
            <p>Zahlungsverzug</p>
            <p>
              Kommen Sie in Zahlungsverzug, so ist Veronikas
              Atelier&nbsp;berechtigt, Verzugszinsen in H&ouml;he von 5
              Prozentpunkten &uuml;ber dem von der Deutschen Bundesbank f&uuml;r
              den Zeitpunkt der Bestellung bekannt gegebenen Basiszinssatz p.a.
              zu fordern. Falls Veronikas Atelier&nbsp;ein h&ouml;herer
              Verzugsschaden nachweisbar entstanden ist, ist Veronikas
              Atelier&nbsp;berechtigt, diesen geltend zu machen.
            </p>
            <p>Zur&uuml;ckbehaltungsrecht</p>
            <p>
              Zur Aus&uuml;bung eines Zur&uuml;ckbehaltungsrechts ist der Kunde
              nur insoweit befugt, als sein Gegenanspruch auf demselben
              Vertragsverh&auml;ltnis beruht.
            </p>
            <p>Lieferung</p>
            <p>
              (1) Die Lieferung erfolgt an die vom Kunden angegebene
              Lieferanschrift, innerhalb von{' '}
            </p>
            <p>- Deutschland</p>
            <p>
              (2) Macht h&ouml;here Gewalt (Naturkatastrophen, Krieg,
              B&uuml;rgerkrieg, Terroranschlag) die Lieferung oder eine sonstige
              Leistung dauerhaft unm&ouml;glich, ist eine Leistungspflicht von
              Veronikas Atelier&nbsp;ausgeschlossen. Bereits gezahlte
              Betr&auml;ge werden von Veronikas Atelier&nbsp;unverz&uuml;glich
              erstattet.
            </p>
            <p>
              (3) Veronikas Atelier&nbsp;kann au&szlig;erdem die Leistung
              verweigern, soweit diese einen Aufwand erfordert, der unter
              Beachtung des Inhalts des Kaufvertrages und der Gebote von Treu
              und Glauben in einem groben Missverh&auml;ltnis zu dem Interesse
              des Kunden an der Erf&uuml;llung des Kaufvertrages steht. Bereits
              gezahlte Betr&auml;ge werden von Veronikas
              Atelier&nbsp;unverz&uuml;glich erstattet.
            </p>
            <p>
              (4) Sperrgut (Pakete mit einem gr&ouml;&szlig;eren Volumen als 1
              qm) werden in der Regel per Spedition geliefert. Veronikas
              Atelier&nbsp;weist ausdr&uuml;cklich darauf hin, dass diese Ware
              nicht ins Haus getragen wird.
            </p>
            <p>G&uuml;nstige Versandart bei R&uuml;cksendung</p>
            <p>
              (1) Bitte verwenden Sie bei der R&uuml;cksendung der Ware und des
              Zubeh&ouml;rs nach M&ouml;glichkeit die Originalverpackung, auch
              wenn diese durch eine &Ouml;ffnung zur Funktionspr&uuml;fung
              besch&auml;digt sein sollte.
            </p>
            <p>
              (2) Bitte verwenden Sie zur R&uuml;cksendung den der
              Warenlieferung beigef&uuml;gten, vollst&auml;ndig frankierten und
              adressierten R&uuml;cksendeaufkleber. Es handelt sich dabei um die
              einfachste und kosteng&uuml;nstigste Versandm&ouml;glichkeit. Sie
              trifft keine Verpflichtung zur Verwendung dieses
              R&uuml;cksendeverfahrens. Wenn Sie allerdings eine unn&ouml;tig
              teure Versandart w&auml;hlen, k&ouml;nnen Sie unter Umst&auml;nden
              verpflichtet sein, die gegen&uuml;ber einer g&uuml;nstigen
              Versandart erh&ouml;hten Kosten an uns zu zahlen.{' '}
            </p>
            <p>M&auml;ngelrechte</p>
            <p>
              (1) Ein bereits bei der Lieferung mangelhaftes Produkt
              (Gew&auml;hrleistungsfall) wird Veronikas Atelier&nbsp;nach Wahl
              des Kunden auf Kosten von Veronikas Atelier&nbsp;durch ein
              mangelfreies ersetzen oder fachgerecht reparieren lassen
              (Nacherf&uuml;llung). Der Kunde wird darauf hingewiesen, dass kein
              Gew&auml;hrleistungsfall vorliegt, wenn das Produkt bei
              Gefahr&uuml;bergang die vereinbarte Beschaffenheit hatte. Ein
              Gew&auml;hrleistungsfall liegt insbesondere in folgenden
              F&auml;llen nicht vor:
            </p>
            <ol>
              <li>
                <span>
                  a) bei Sch&auml;den, die beim Kunden durch Missbrauch oder
                  unsachgem&auml;&szlig;en Gebrauch entstanden sind,
                </span>
              </li>
              <li>
                <span>
                  b) bei Sch&auml;den, die dadurch entstanden sind, dass die
                  Produkte beim Kunden sch&auml;dlichen &auml;u&szlig;eren
                  Einfl&uuml;ssen ausgesetzt worden sind (insbesondere extremen
                  Temperaturen, Feuchtigkeit, au&szlig;ergew&ouml;hnlicher
                  physikalischer oder elektrischer Beanspruchung,
                  Spannungsschwankungen, Blitzschlag, statischer
                  Elektrizit&auml;t, Feuer).
                </span>
              </li>
            </ol>
            <p>
              (2) Veronikas Atelier&nbsp;leistet ferner keine Gew&auml;hr
              f&uuml;r einen Fehler, der durch unsachgem&auml;&szlig;e Reparatur
              durch einen nicht vom Hersteller autorisierten Servicepartner
              entstanden ist.
            </p>
            <p>
              (3) Erfordert die vom Kunden gew&uuml;nschte Art der
              Nacherf&uuml;llung (Ersatzlieferung oder Reparatur) einen Aufwand,
              der in Anbetracht des Produktpreises unter Beachtung des
              Vertragsinhaltes und der Gebote von Treu und Glauben in einem
              groben Missverh&auml;ltnis zu dem Leistungsinteresse des Kunden
              steht &ndash; wobei insbesondere der Wert des Kaufgegenstandes im
              mangelfreien Zustand, die Bedeutung des Mangels und die Frage zu
              ber&uuml;cksichtigen sind, ob auf die andere Art der
              Nacherf&uuml;llung ohne erhebliche Nachteile f&uuml;r den Kunden
              zur&uuml;ckgegriffen werden kann &ndash; beschr&auml;nkt sich der
              Anspruch des Kunden auf die jeweils andere Art der
              Nacherf&uuml;llung. Das Recht von Veronikas Atelier, auch diese
              andere Art der Nacherf&uuml;llung unter der vorgenannten
              Voraussetzung zu verweigern, bleibt unber&uuml;hrt.{' '}
            </p>
            <p>
              (4) Sowohl f&uuml;r den Fall der Reparatur als auch f&uuml;r den
              Fall der Ersatzlieferung ist der Kunde verpflichtet, das Produkt
              auf Kosten von Veronikas Atelier&nbsp;unter Angabe der
              Auftragsnummer an die von ihr angegebene R&uuml;cksendeadresse
              einzusenden. Vor der Einsendung hat der Kunde von ihm
              eingef&uuml;gte Gegenst&auml;nde aus dem Produkt zu entfernen.
              Veronikas Atelier&nbsp;ist nicht verpflichtet, das Produkt auf den
              Einbau solcher Gegenst&auml;nde zu untersuchen. F&uuml;r den
              Verlust solcher Gegenst&auml;nde haftet Veronikas
              Atelier&nbsp;nicht, es sei denn, es war bei R&uuml;cknahme des
              Produktes f&uuml;r Veronikas Atelier&nbsp;ohne Weiteres erkennbar,
              dass ein solcher Gegenstand in das Produkt eingef&uuml;gt worden
              ist (in diesem Fall informiert Veronikas Atelier&nbsp;den Kunden
              und h&auml;lt den Gegenstand f&uuml;r den Kunden zur Abholung
              bereit; der Kunde tr&auml;gt die dabei entstehenden Kosten). Der
              Kunde hat zudem, bevor er ein Produkt zur Reparatur oder zum
              Austausch einsendet, gegebenenfalls separate Sicherungskopien der
              auf dem Produkt befindlichen Systemsoftware, der Anwendungen und
              aller Daten auf einem separaten Datentr&auml;ger zu erstellen und
              alle Passw&ouml;rter zu deaktivieren. Eine Haftung f&uuml;r
              Datenverlust wird nicht &uuml;bernommen. Ebenso obliegt es dem
              Kunden, nachdem ihm das reparierte Produkt oder das Ersatzprodukt
              zur&uuml;ckgesandt worden ist, die Software und Daten zu
              installieren und die Passw&ouml;rter zu reaktivieren.{' '}
            </p>
            <p>
              (5) Sendet der Kunde die Ware ein, um ein Austauschprodukt zu
              bekommen, richtet sich die R&uuml;ckgew&auml;hr des mangelhaften
              Produktes nach folgender Ma&szlig;gabe: Sofern der Kunde die Ware
              zwischen Lieferung und R&uuml;cksendung in mangelfreiem Zustand
              benutzen konnte, hat dieser den Wert der von ihm gezogenen
              Nutzungen zu erstatten. F&uuml;r einen nicht durch den Mangel
              eingetretenen Untergang oder die weitere Verschlechterung der Ware
              sowie f&uuml;r die nicht durch den Mangel eingetretene
              Unm&ouml;glichkeit der Herausgabe der Ware im Zeitraum zwischen
              Lieferung der Ware und R&uuml;cksendung der Ware hat der Kunde
              Wertersatz zu leisten. Der Kunde hat keinen Wertersatz f&uuml;r
              die durch den bestimmungsgem&auml;&szlig;en Gebrauch der Ware
              entstandene Verschlechterung der Ware zu leisten. Die Pflicht zum
              Wertersatz entf&auml;llt f&uuml;r die R&uuml;cksendung eines
              mangelhaften Produktes im Gew&auml;hrleistungsfall ferner,
            </p>
            <ol>
              <li>
                <span>
                  a) wenn sich der zum R&uuml;cktritt berechtigende Mangel erst
                  w&auml;hrend der Verarbeitung oder Umgestaltung gezeigt hat,
                </span>
              </li>
              <li>
                <span>
                  b) wenn Veronikas Atelier&nbsp;die Verschlechterung oder den
                  Untergang zu vertreten hat oder der Schaden auch bei Veronikas
                  Atelier&nbsp;eingetreten w&auml;re,
                </span>
              </li>
              <li>
                <span>
                  c) wenn die Verschlechterung oder der Untergang beim Kunden
                  eingetreten ist, obwohl dieser diejenige Sorgfalt beachtet
                  hat, die er in eigenen Angelegenheiten anzuwenden pflegt.
                </span>
              </li>
            </ol>
            <p>
              (6) Die Schadensersatzpflicht des Kunden bei einer vom Kunden zu
              vertretenden Verletzung der R&uuml;cksendungspflicht richtet sich
              nach Ma&szlig;gabe der gesetzlichen Bestimmungen.
            </p>
            <p>
              (7) Der Kunde kann nach seiner Wahl vom Vertrag zur&uuml;cktreten
              oder den Kaufpreis mindern, wenn die Reparatur oder
              Ersatzlieferung innerhalb einer angemessenen Frist nicht zu einem
              vertragsgerechten Zustand des Produktes gef&uuml;hrt hat.{' '}
            </p>
            <p>
              (8) Dar&uuml;ber hinaus k&ouml;nnen auch Anspr&uuml;che gegen den
              Hersteller im Rahmen einer von diesem einger&auml;umten Garantie
              bestehen, die sich nach den entsprechenden Garantiebedingungen
              richten.{' '}
            </p>
            <p>
              (9) Die gesetzliche Gew&auml;hrleistung von Veronikas
              Atelier&nbsp;endet zwei Jahre ab Lieferung der Ware. Die Frist
              beginnt mit dem Erhalt der Ware.{' '}
            </p>
            <p>Anwendbares Recht</p>
            <p>
              Der zwischen Ihnen und Veronikas Atelier&nbsp;abgeschlossene
              Vertrag unterliegt ausschlie&szlig;lich dem Recht der
              Bundesrepublik Deutschland unter ausdr&uuml;cklichem Ausschluss
              des UN-Kaufrechts. Unber&uuml;hrt davon bleiben die zwingenden
              Bestimmungen des Staates, in dem Sie Ihren gew&ouml;hnlichen
              Aufenthalt haben.
            </p>
            <p>Gerichtsstand</p>
            <p>
              Sofern Sie entgegen Ihren Angaben bei der Bestellung keinen
              Wohnsitz in der Bundesrepublik Deutschland haben oder nach
              Vertragsabschluss Ihren Wohnsitz ins Ausland verlegen oder Ihr
              Wohnsitz zum Zeitpunkt der Klageerhebung nicht bekannt ist, ist
              Gerichtsstand f&uuml;r alle Streitigkeiten aus und im Zusammenhang
              mit dem Vertragsverh&auml;ltnis Schwerin.{' '}
            </p>
            <p>Streitbeilegung</p>
            <p>
              Allgemeine Informationspflichten zur alternativen Streitbeilegung
              nach Art. 14 Abs. 1 ODR-VO und &sect; 36 VSBG
              (Verbraucherstreitbeilegungsgesetz):
            </p>
            <p>
              Die europ&auml;ische Kommission stellt eine Plattform zur
              Online-Streitbelegung (OS) zur Verf&uuml;gung, die Sie unter
              dieser Adresse finden:{' '}
              <a href="https://www.google.com/url?q=http://ec.europa.eu/consumers/odr/&amp;sa=D&amp;source=editors&amp;ust=1613161559044000&amp;usg=AOvVaw374eftkMzLyqSIfngZoRPd">
                http://ec.europa.eu/consumers/odr/
              </a>
              &nbsp;. Zur Teilnahme an einem Streitbeilegungsverfahren vor einer
              Verbraucherschlichtungsstelle sind wir nicht verpflichtet und auch
              nicht bereit.{' '}
            </p>
            <p>Schlussbestimmungen</p>
            <p>
              (1) Sollten einzelne Bestimmungen dieses Vertrages ganz oder
              teilweise unwirksam oder nichtig sein oder werden, so wird dadurch
              die Wirksamkeit des Vertrages im &Uuml;brigen nicht ber&uuml;hrt,
              insoweit ein Vertragspartner hierdurch nicht unangemessen
              benachteiligt wird.
            </p>
            <p>
              (2) &Auml;nderungen oder Erg&auml;nzungen dieses Vertrages
              bed&uuml;rfen der Schriftform.
            </p>
          </div>
        </div>
      </PageSection>
    </Layout>
  )
}

export default AGB
